
  import { defineComponent, ref, computed, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { convertDateDDMMYYYY } from '../../utils/dateFormat';

  export default defineComponent({
    name: 'attachment',
    props: {
      isDisabled: { type: Boolean, default: false },
      attachments: {
        type: Array,
        default: () => [],
      },
    },

    emit: ['submit-create', 'delete'],
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup(props, { emit }) {
      console.log(props.attachments);
      const { t, te } = useI18n();
      const store = useStore();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const disabledBtn = ref(props.isDisabled);
      const attachmentRef = ref();
      const attachmentsData = ref<any>(props.attachments);
      const previewNewImage = ref<string[]>([]);

      watch(
        () => props.isDisabled,
        (newValue) => {
          disabledBtn.value = newValue;
        }
      );

      const selectedDocumentTypeId = ref('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const attachmentSchema = Yup.object().shape({
        documentTypeId: Yup.string()
          .notOneOf(['0'], () => translate('DOC_TYPE_IS_REQUIRED_FIELD'))
          .required(() => translate('DOC_TYPE_IS_REQUIRED_FIELD'))
          .label('document Type'),
        attachments: Yup.mixed().required('Attachment is required'),
      });

      const lookupsList = computed(() => store.getters.allLookupsList);

      const documentsType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });
      const resetAttachment = () => {
        attachmentRef.value.value = null;
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      const onSubmitCreateAttachment = async (values, { resetForm }) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const formData = new FormData();
        for (let i = 0; i < values.attachments.length; i++) {
          previewNewImage.value.push(
            URL.createObjectURL(values.attachments[i])
          );

          formData.append('attachments', values.attachments[i]);
        }
        formData.append('documentTypeId', values.documentTypeId);

        emit('submit-create', formData, () => {
          resetAttachment();
          resetForm({
            values: {
              documentTypeId: '',
              attachments: null,
            },
          });
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          previewNewImage.value = [];
        }
      };
      const removeImage = (id) => {
        emit('delete', id);
      };

      return {
        submitButton,
        translate,
        can,
        attachmentSchema,
        documentsType,
        disabledBtn,
        onSubmitCreateAttachment,
        attachmentRef,
        selectedDocumentTypeId,
        resetAttachment,
        attachmentsData,
        convertDateDDMMYYYY,
        previewNewImage,
        removeImage,
      };
    },
  });
